@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin container {
  width: 1200px;
  margin: 0 auto;
}
