@charset 'utf-8';

// prerequisites

@import '~sass-mq';
@import 'abstracts/*';

// vendors

@import-normalize;

@import 'vendors/*';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700&display=swap');

// categorized rules

@import 'utils/*';
@import 'base/*';
@import 'layout/*';
@import 'components/*';
