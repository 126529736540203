* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  min-width: mq-get-breakpoint-width(sm);

  @include mq(md) {
    min-width: mq-get-breakpoint-width(lg);
  }
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  color: #222;
  letter-spacing: 0.03em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg {
  &:not([fill]) {
    fill: currentColor;
  }
}

table {
  border-collapse: collapse;
}

[data-whatinput='mouse'] {
  :focus {
    outline: none;
  }
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
