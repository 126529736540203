.c-contact {
  @include mq(md) {
    display: flex;
  }

  &__inner {
    @include mq(md) {
      width: 50%;
    }
  }

  &__link {
    display: block;
    padding: 63px 0 65px;
    color: #fff;
    text-align: center;
    transition: background-color 300ms;

    @at-root .c-contact__inner:nth-child(1) & {
      background-color: #0052ad;

      &:hover {
        background-color: #004b9b;
      }
    }

    @at-root .c-contact__inner:nth-child(2) & {
      background-color: #00adef;

      &:hover {
        background-color: #0099d5;
      }
    }

    @include mq(md) {
      padding: 131px 0;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    font-size: 20px;
    line-height: 1.32;

    @include mq(md) {
      font-size: 22px;
      line-height: 1.81;
    }
  }

  &__text {
    margin: 16px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.66;

    @include mq(md) {
      margin: 33px 0 0;
      font-size: 14px;
    }
  }
}
