.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 68px;

  @include mq(md) {
    height: 94px;
  }

  &__wrapper {
    @include mq(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      background-color: #fff;
    }
  }

  &__inner {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    padding-left: 18px;
    background-color: #fff;

    @include mq(md) {
      padding-left: 50px;
    }
  }

  &__logo {
    margin: 2px 0 0;

    @include mq(md) {
      margin: -6px 0 0;
    }

    img {
      width: 108px;

      @include mq(md) {
        width: 138px;
      }
    }
  }

  &__menu {
    width: 68px;
    height: 68px;

    @include mq(md) {
      display: none;
    }
  }

  &__menu-button {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgb(0, 82, 173) 0%, rgb(0, 173, 239) 100%); // stylelint-disable-line
    border-width: 0;

    span {
      position: absolute;
      left: 50%;
      width: 25px;
      height: 1px;
      background-color: #fff;
      transition: transform 300ms, opacity 300ms;
      transform: translateX(-50%);

      &:nth-child(1) {
        transform: translate(-50%, -8px);

        @at-root .header__menu-button.is-active span:nth-child(1) {
          transform: translateX(-50%) rotate(45deg);
        }
      }

      &:nth-child(2) {
        @at-root .header__menu-button.is-active span:nth-child(2) {
          opacity: 0;
          transform: translateX(0);
        }
      }

      &:nth-child(3) {
        transform: translate(-50%, 8px);

        @at-root .header__menu-button.is-active span:nth-child(3) {
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }
  }

  &__menu-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 68px;
    overflow-y: scroll;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms, visibility 300ms;
    -webkit-overflow-scrolling: touch;

    @include mq(md) {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
    }

    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__menu-inner {
    padding: 56px 25px 80px;
    background-image: linear-gradient(90deg, rgb(0, 82, 173) 0%, rgb(0, 173, 239) 100%); // stylelint-disable-line

    @include mq(md) {
      display: flex;
      padding: 0;
      background-image: none;
    }
  }

  .list {
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.1em;

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #222;
    }

    &__item {
      padding: 0 15px;

      @include mq(md) {
        padding: 0;
      }

      + .list__item {
        margin: 23px 0 0;

        @include mq(md) {
          margin: 0 0 0 47px;
        }
      }
    }

    &__link {
      position: relative;
      display: block;
      transition: color 300ms;

      @at-root .list__item:not(:last-child) .list__link {
        &::before {
          width: 8px;
          height: 14px;
          margin-right: 3px;
          background-image: url('/assets/images/pages/common/icon_arrow_default.svg');

          @include mq(md) {
            content: none;
          }
        }
      }

      @at-root .list__item:last-child .list__link {
        &::before,
        &::after {
          display: inline-block;
          width: 15px;
          height: 1px;
          background-color: #fff;
        }

        &::after {
          transition: transform 300ms;
          transform: translateY(-50%) rotate(-90deg);
        }

        &.is-active {
          &::after {
            transform: translateY(-50%) rotate(0);
          }
        }
      }

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        content: '';
        background-size: contain;
        transform: translateY(-50%);

        @include mq(md) {
          content: none;
        }
      }

      &:hover {
        @include mq(md) {
          color: #0052ad;
        }
      }
    }
  }

  .list-inner {
    height: 0;
    margin: 12px 0 0 20px;
    overflow: hidden;
    transition: height 300ms;

    @include mq(md) {
      height: auto;
      overflow: visible;
    }

    &__contents {
      display: flex;

      + .list-inner__contents {
        margin: 6px 0 0;
      }
    }

    &__item {
      + .list-inner__item {
        margin-left: 30px;
      }
    }

    &__link {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .button-wrapper {
    display: block;
    padding: 0 15px;
    margin: 43px 0 0;

    @include mq(md) {
      padding: 0;
      margin: 0 0 0 90px;
    }

    &__item {
      + .button-wrapper__item {
        margin: 10px 0 0;

        @include mq(md) {
          margin: 0;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      color: #fff;

      @include mq(md) {
        width: 260px;
        height: 94px;
        font-size: 14px;
      }

      &--01 {
        position: relative;
        padding-right: 15px;
        border: 1px solid currentColor;

        @include mq(md) {
          padding: 0;
          border: 0;
          background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line
        }

        &::before {
          @include mq(md) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-image: linear-gradient(90deg, rgb(0, 82, 173) 30%, rgb(0, 173, 239) 100%); // stylelint-disable-line
            opacity: 0;
            transition: opacity 450ms;
          }
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }

        .icon {
          position: relative;
          z-index: 1;
          display: inline-block;
          width: 15px;
          height: 19px;
          margin-right: 14px;
          background-image: url('/assets/images/pages/common/icon_document.svg');
          background-size: contain;

          @include mq(md) {
            width: 17px;
            height: 22px;
            margin-right: 22px;
          }
        }
      }

      &--02 {
        background-color: #004999;
      }

      &--03 {
        background-color: #00b9ff;
      }
    }

    &__link-inner {
      @include mq(md) {
        position: relative;
        z-index: 1;
      }
    }
  }
}
