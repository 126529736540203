// stylelint-disable declaration-no-important

@each $value in $displays {
  .u-d-#{$value} {
    display: $value !important;
  }
}

@each $breakpoint in map-keys($mq-breakpoints) {
  @include mq($breakpoint) {
    $infix: '-#{$breakpoint}';

    @each $value in $displays {
      .u-d#{$infix}-#{$value} {
        display: $value !important;
      }
    }
  }
}
