@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700&display=swap");
@import-normalize;
.u-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.u-d-none {
  display: none !important;
}

.u-d-inline {
  display: inline !important;
}

.u-d-inline-block {
  display: inline-block !important;
}

.u-d-block {
  display: block !important;
}

.u-d-table {
  display: table !important;
}

.u-d-table-row {
  display: table-row !important;
}

.u-d-table-cell {
  display: table-cell !important;
}

.u-d-flex {
  display: flex !important;
}

.u-d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 20em) {
  .u-d-xs-none {
    display: none !important;
  }

  .u-d-xs-inline {
    display: inline !important;
  }

  .u-d-xs-inline-block {
    display: inline-block !important;
  }

  .u-d-xs-block {
    display: block !important;
  }

  .u-d-xs-table {
    display: table !important;
  }

  .u-d-xs-table-row {
    display: table-row !important;
  }

  .u-d-xs-table-cell {
    display: table-cell !important;
  }

  .u-d-xs-flex {
    display: flex !important;
  }

  .u-d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 23.4375em) {
  .u-d-sm-none {
    display: none !important;
  }

  .u-d-sm-inline {
    display: inline !important;
  }

  .u-d-sm-inline-block {
    display: inline-block !important;
  }

  .u-d-sm-block {
    display: block !important;
  }

  .u-d-sm-table {
    display: table !important;
  }

  .u-d-sm-table-row {
    display: table-row !important;
  }

  .u-d-sm-table-cell {
    display: table-cell !important;
  }

  .u-d-sm-flex {
    display: flex !important;
  }

  .u-d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 48em) {
  .u-d-md-none {
    display: none !important;
  }

  .u-d-md-inline {
    display: inline !important;
  }

  .u-d-md-inline-block {
    display: inline-block !important;
  }

  .u-d-md-block {
    display: block !important;
  }

  .u-d-md-table {
    display: table !important;
  }

  .u-d-md-table-row {
    display: table-row !important;
  }

  .u-d-md-table-cell {
    display: table-cell !important;
  }

  .u-d-md-flex {
    display: flex !important;
  }

  .u-d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 80em) {
  .u-d-lg-none {
    display: none !important;
  }

  .u-d-lg-inline {
    display: inline !important;
  }

  .u-d-lg-inline-block {
    display: inline-block !important;
  }

  .u-d-lg-block {
    display: block !important;
  }

  .u-d-lg-table {
    display: table !important;
  }

  .u-d-lg-table-row {
    display: table-row !important;
  }

  .u-d-lg-table-cell {
    display: table-cell !important;
  }

  .u-d-lg-flex {
    display: flex !important;
  }

  .u-d-lg-inline-flex {
    display: inline-flex !important;
  }
}
.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  min-width: 375px;
}
@media (min-width: 48em) {
  html {
    min-width: 1280px;
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  color: #222;
  letter-spacing: 0.03em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg:not([fill]) {
  fill: currentColor;
}

table {
  border-collapse: collapse;
}

[data-whatinput=mouse] :focus {
  outline: none;
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

.l-footer {
  margin: 68px 0 0;
}
@media (min-width: 48em) {
  .l-footer {
    margin: 140px 0 0;
  }
}

.l-main {
  padding: 68px 0 0;
}
@media (min-width: 48em) {
  .l-main {
    padding: 94px 0 0;
  }
}

@media (min-width: 48em) {
  .c-contact {
    display: flex;
  }
}
@media (min-width: 48em) {
  .c-contact__inner {
    width: 50%;
  }
}
.c-contact__link {
  display: block;
  padding: 63px 0 65px;
  color: #fff;
  text-align: center;
  transition: background-color 300ms;
}
.c-contact__inner:nth-child(1) .c-contact__link {
  background-color: #0052ad;
}
.c-contact__inner:nth-child(1) .c-contact__link:hover {
  background-color: #004b9b;
}

.c-contact__inner:nth-child(2) .c-contact__link {
  background-color: #00adef;
}
.c-contact__inner:nth-child(2) .c-contact__link:hover {
  background-color: #0099d5;
}

@media (min-width: 48em) {
  .c-contact__link {
    padding: 131px 0;
  }
}
.c-contact__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 78px;
  font-size: 20px;
  line-height: 1.32;
}
@media (min-width: 48em) {
  .c-contact__heading {
    font-size: 22px;
    line-height: 1.81;
  }
}
.c-contact__text {
  margin: 16px 0 0;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.66;
}
@media (min-width: 48em) {
  .c-contact__text {
    margin: 33px 0 0;
    font-size: 14px;
  }
}

.footer {
  padding: 35px 25px 43px;
  background-color: #17222e;
}
@media (min-width: 48em) {
  .footer {
    padding: 90px 0 112px;
  }
}
@media (min-width: 48em) {
  .footer__inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
}
@media (min-width: 48em) {
  .footer .nav-list {
    display: flex;
  }
}
.footer .nav-list__item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 48em) {
  .footer .nav-list__item {
    border: 0;
  }
}
.footer .nav-list__item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 48em) {
  .footer .nav-list__item:last-child {
    border: 0;
  }
}
@media (min-width: 48em) {
  .footer .nav-list__item + .nav-list__item {
    margin-left: 70px;
  }
}
.footer .nav-list__link {
  position: relative;
  display: block;
  padding: 16px 10px 17px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}
@media (min-width: 48em) {
  .footer .nav-list__link {
    padding: 0;
  }
}
.footer .nav-list__link::before, .footer .nav-list__link::after {
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  background-size: contain;
  transform: translateY(-50%);
}
@media (min-width: 48em) {
  .footer .nav-list__link::before, .footer .nav-list__link::after {
    content: none;
  }
}
@media (min-width: 48em) {
  .footer .nav-list__link:hover {
    text-decoration: underline;
  }
}
.footer .nav-list__link--arrow::before {
  width: 14px;
  height: 8px;
  background-image: url("/assets/images/pages/common/icon_arrow.svg");
}
.footer .nav-list__link--toogle::before, .footer .nav-list__link--toogle::after {
  width: 12px;
  height: 1px;
  background-image: url("/assets/images/pages/common/icon_border.svg");
}
.footer .nav-list__link--toogle::after {
  transition: transform 300ms;
  transform: translateY(-50%) rotate(-90deg);
}
.footer .nav-list__link--toogle.is-active::after {
  transform: translateY(-50%) rotate(0);
}
.footer .nav-list .lower {
  height: 0;
  overflow: hidden;
  transition: height 300ms;
}
@media (min-width: 48em) {
  .footer .nav-list .lower {
    height: auto;
    overflow: visible;
  }
}
.footer .nav-list .lower__inner {
  padding: 0 0 25px 30px;
}
@media (min-width: 48em) {
  .footer .nav-list .lower__inner {
    padding: 16px 0 0 18px;
  }
}
.footer .nav-list .lower__item + .lower__item {
  margin: 2px 0 0;
}
@media (min-width: 48em) {
  .footer .nav-list .lower__item + .lower__item {
    margin: 5px 0 0;
  }
}
.footer .nav-list .lower__link {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}
.footer .nav-list .lower__link span {
  display: inline-block;
  margin-left: 3px;
}
@media (min-width: 48em) {
  .footer .nav-list .lower__link span {
    margin-left: 8px;
  }
  .footer .nav-list .lower__link:hover span {
    text-decoration: underline;
  }
}
.footer .nav-other {
  padding: 27px 10px 0;
}
@media (min-width: 48em) {
  .footer .nav-other {
    padding: 0 0 0 65px;
    margin-left: 63px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
}
@media (min-width: 48em) {
  .footer .nav-other__item:first-child {
    margin-top: -5px;
  }
}
.footer .nav-other__item + .nav-other__item {
  margin: 8px 0 0;
}
@media (min-width: 48em) {
  .footer .nav-other__item + .nav-other__item {
    margin: 11px 0 0;
  }
}
.footer .nav-other__link {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}
@media (min-width: 48em) {
  .footer .nav-other__link:hover {
    text-decoration: underline;
  }
}
.footer .beneath {
  margin: 35px 0 0;
}
@media (min-width: 48em) {
  .footer .beneath {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding: 60px 0 0;
  }
}
.footer .beneath__link {
  transition: opacity 300ms;
}
@media (min-width: 48em) {
  .footer .beneath__link:hover {
    opacity: 0.8;
  }
}
.footer .beneath__copy {
  margin: 35px 0 0;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  letter-spacing: 0;
}
@media (min-width: 48em) {
  .footer .beneath__copy {
    margin: 0;
    font-size: 10px;
    text-align: left;
  }
}
.footer .beneath img {
  width: 100%;
}
@media (min-width: 48em) {
  .footer .beneath img {
    width: 310px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 68px;
}
@media (min-width: 48em) {
  .header {
    height: 94px;
  }
}
@media (min-width: 48em) {
  .header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
  }
}
.header__inner {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  background-color: #fff;
}
@media (min-width: 48em) {
  .header__inner {
    padding-left: 50px;
  }
}
.header__logo {
  margin: 2px 0 0;
}
@media (min-width: 48em) {
  .header__logo {
    margin: -6px 0 0;
  }
}
.header__logo img {
  width: 108px;
}
@media (min-width: 48em) {
  .header__logo img {
    width: 138px;
  }
}
.header__menu {
  width: 68px;
  height: 68px;
}
@media (min-width: 48em) {
  .header__menu {
    display: none;
  }
}
.header__menu-button {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #0052ad 0%, #00adef 100%);
  border-width: 0;
}
.header__menu-button span {
  position: absolute;
  left: 50%;
  width: 25px;
  height: 1px;
  background-color: #fff;
  transition: transform 300ms, opacity 300ms;
  transform: translateX(-50%);
}
.header__menu-button span:nth-child(1) {
  transform: translate(-50%, -8px);
}
.header__menu-button.is-active span:nth-child(1) {
  transform: translateX(-50%) rotate(45deg);
}

.header__menu-button.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(0);
}

.header__menu-button span:nth-child(3) {
  transform: translate(-50%, 8px);
}
.header__menu-button.is-active span:nth-child(3) {
  transform: translateX(-50%) rotate(-45deg);
}

.header__menu-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 68px;
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms, visibility 300ms;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 48em) {
  .header__menu-nav {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    overflow: hidden;
    visibility: visible;
    opacity: 1;
  }
}
.header__menu-nav.is-active {
  visibility: visible;
  opacity: 1;
}
.header__menu-inner {
  padding: 56px 25px 80px;
  background-image: linear-gradient(90deg, #0052ad 0%, #00adef 100%);
}
@media (min-width: 48em) {
  .header__menu-inner {
    display: flex;
    padding: 0;
    background-image: none;
  }
}
.header .list {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .header .list {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222;
  }
}
.header .list__item {
  padding: 0 15px;
}
@media (min-width: 48em) {
  .header .list__item {
    padding: 0;
  }
}
.header .list__item + .list__item {
  margin: 23px 0 0;
}
@media (min-width: 48em) {
  .header .list__item + .list__item {
    margin: 0 0 0 47px;
  }
}
.header .list__link {
  position: relative;
  display: block;
  transition: color 300ms;
}
.list__item:not(:last-child) .list__link::before {
  width: 8px;
  height: 14px;
  margin-right: 3px;
  background-image: url("/assets/images/pages/common/icon_arrow_default.svg");
}
@media (min-width: 48em) {
  .list__item:not(:last-child) .list__link::before {
    content: none;
  }
}

.list__item:last-child .list__link::before, .list__item:last-child .list__link::after {
  display: inline-block;
  width: 15px;
  height: 1px;
  background-color: #fff;
}
.list__item:last-child .list__link::after {
  transition: transform 300ms;
  transform: translateY(-50%) rotate(-90deg);
}
.list__item:last-child .list__link.is-active::after {
  transform: translateY(-50%) rotate(0);
}

.header .list__link::before, .header .list__link::after {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  background-size: contain;
  transform: translateY(-50%);
}
@media (min-width: 48em) {
  .header .list__link::before, .header .list__link::after {
    content: none;
  }
}
@media (min-width: 48em) {
  .header .list__link:hover {
    color: #0052ad;
  }
}
.header .list-inner {
  height: 0;
  margin: 12px 0 0 20px;
  overflow: hidden;
  transition: height 300ms;
}
@media (min-width: 48em) {
  .header .list-inner {
    height: auto;
    overflow: visible;
  }
}
.header .list-inner__contents {
  display: flex;
}
.header .list-inner__contents + .list-inner__contents {
  margin: 6px 0 0;
}
.header .list-inner__item + .list-inner__item {
  margin-left: 30px;
}
.header .list-inner__link {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}
.header .button-wrapper {
  display: block;
  padding: 0 15px;
  margin: 43px 0 0;
}
@media (min-width: 48em) {
  .header .button-wrapper {
    padding: 0;
    margin: 0 0 0 90px;
  }
}
.header .button-wrapper__item + .button-wrapper__item {
  margin: 10px 0 0;
}
@media (min-width: 48em) {
  .header .button-wrapper__item + .button-wrapper__item {
    margin: 0;
  }
}
.header .button-wrapper__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: #fff;
}
@media (min-width: 48em) {
  .header .button-wrapper__link {
    width: 260px;
    height: 94px;
    font-size: 14px;
  }
}
.header .button-wrapper__link--01 {
  position: relative;
  padding-right: 15px;
  border: 1px solid currentColor;
}
@media (min-width: 48em) {
  .header .button-wrapper__link--01 {
    padding: 0;
    border: 0;
    background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
  }
}
@media (min-width: 48em) {
  .header .button-wrapper__link--01::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(90deg, #0052ad 30%, #00adef 100%);
    opacity: 0;
    transition: opacity 450ms;
  }
}
.header .button-wrapper__link--01:hover::before {
  opacity: 1;
}
.header .button-wrapper__link--01 .icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 15px;
  height: 19px;
  margin-right: 14px;
  background-image: url("/assets/images/pages/common/icon_document.svg");
  background-size: contain;
}
@media (min-width: 48em) {
  .header .button-wrapper__link--01 .icon {
    width: 17px;
    height: 22px;
    margin-right: 22px;
  }
}
.header .button-wrapper__link--02 {
  background-color: #004999;
}
.header .button-wrapper__link--03 {
  background-color: #00b9ff;
}
@media (min-width: 48em) {
  .header .button-wrapper__link-inner {
    position: relative;
    z-index: 1;
  }
}

.c-heading__sub {
  display: block;
  margin: 3px 0 0;
  font-size: 11px;
  font-weight: 400;
}
@media (min-width: 48em) {
  .c-heading__sub {
    margin: 15px 0 0;
    font-size: 12px;
  }
}