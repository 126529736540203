.c-heading {
  &__main {
    //
  }

  &__sub {
    display: block;
    margin: 3px 0 0;
    font-size: 11px;
    font-weight: 400;

    @include mq(md) {
      margin: 15px 0 0;
      font-size: 12px;
    }
  }
}
