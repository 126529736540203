.footer {
  padding: 35px 25px 43px;
  background-color: #17222e;

  @include mq(md) {
    padding: 90px 0 112px;
  }

  &__inner {
    @include mq(md) {
      @include container();

      display: flex;
    }
  }

  .nav-list {
    @include mq(md) {
      display: flex;
    }

    &__item {
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      @include mq(md) {
        border: 0;
      }

      &:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        @include mq(md) {
          border: 0;
        }
      }

      + .nav-list__item {
        @include mq(md) {
          margin-left: 70px;
        }
      }
    }

    &__link {
      position: relative;
      display: block;
      padding: 16px 10px 17px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);

      @include mq(md) {
        padding: 0;
      }

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        content: '';
        background-size: contain;
        transform: translateY(-50%);

        @include mq(md) {
          content: none;
        }
      }

      &:hover {
        @include mq(md) {
          text-decoration: underline;
        }
      }

      &--arrow {
        &::before {
          width: 14px;
          height: 8px;
          background-image: url('/assets/images/pages/common/icon_arrow.svg');
        }
      }

      &--toogle {
        &::before,
        &::after {
          width: 12px;
          height: 1px;
          background-image: url('/assets/images/pages/common/icon_border.svg');
        }

        &::after {
          transition: transform 300ms;
          transform: translateY(-50%) rotate(-90deg);
        }

        &.is-active {
          &::after {
            transform: translateY(-50%) rotate(0);
          }
        }
      }
    }

    .lower {
      height: 0;
      overflow: hidden;
      transition: height 300ms;

      @include mq(md) {
        height: auto;
        overflow: visible;
      }

      &__inner {
        padding: 0 0 25px 30px;

        @include mq(md) {
          padding: 16px 0 0 18px;
        }
      }

      &__item {
        + .lower__item {
          margin: 2px 0 0;

          @include mq(md) {
            margin: 5px 0 0;
          }
        }
      }

      &__link {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);

        span {
          display: inline-block;
          margin-left: 3px;

          @include mq(md) {
            margin-left: 8px;

            @at-root .footer .nav-list .lower__link:hover span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .nav-other {
    padding: 27px 10px 0;

    @include mq(md) {
      padding: 0 0 0 65px;
      margin-left: 63px;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    &__item {
      &:first-child {
        @include mq(md) {
          margin-top: -5px;
        }
      }

      + .nav-other__item {
        margin: 8px 0 0;

        @include mq(md) {
          margin: 11px 0 0;
        }
      }
    }

    &__link {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);

      &:hover {
        @include mq(md) {
          text-decoration: underline;
        }
      }
    }
  }

  .beneath {
    margin: 35px 0 0;

    @include mq(md) {
      @include container();

      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      padding: 60px 0 0;
    }

    &__link {
      transition: opacity 300ms;

      &:hover {
        @include mq(md) {
          opacity: 0.8;
        }
      }
    }

    &__copy {
      margin: 35px 0 0;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.3);
      text-align: center;
      letter-spacing: 0;

      @include mq(md) {
        margin: 0;
        font-size: 10px;
        text-align: left;
      }
    }

    img {
      width: 100%;

      @include mq(md) {
        width: 310px;
      }
    }
  }
}
